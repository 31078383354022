const countries = [
	{ id: "20", name: "Afghanistan" },
	{ id: "21", name: "Albania" },
	{ id: "22", name: "Algeria" },
	{ id: "23", name: "American Samoa" },
	{ id: "24", name: "Andorra" },
	{ id: "25", name: "Angola" },
	{ id: "26", name: "Anguilla" },
	{ id: "27", name: "Antarctica" },
	{ id: "28", name: "Antigua" },
	{ id: "29", name: "Argentina" },
	{ id: "30", name: "Armenia" },
	{ id: "31", name: "Aruba" },
	{ id: "32", name: "Australia" },
	{ id: "33", name: "Austria" },
	{ id: "34", name: "Azerbaijan" },
	{ id: "35", name: "Bahamas" },
	{ id: "36", name: "Bahrain" },
	{ id: "37", name: "Bangladesh" },
	{ id: "38", name: "Barbados" },
	{ id: "39", name: "Belarus" },
	{ id: "40", name: "Belgium" },
	{ id: "41", name: "Belize" },
	{ id: "42", name: "Benin" },
	{ id: "43", name: "Bermuda" },
	{ id: "44", name: "Bhutan" },
	{ id: "45", name: "Bolivia" },
	{ id: "46", name: "Bosnia-Herzegovina" },
	{ id: "47", name: "Botswana" },
	{ id: "48", name: "Brazil" },
	{ id: "49", name: "British Virgin Islands" },
	{ id: "50", name: "Brunei" },
	{ id: "51", name: "Bulgaria" },
	{ id: "52", name: "Burkina Faso" },
	{ id: "53", name: "Burma (Myanmar)" },
	{ id: "54", name: "Burundi" },
	{ id: "55", name: "Cambodia" },
	{ id: "56", name: "Cameroon" },
	{ id: "1", name: "Canada" },
	{ id: "57", name: "Cape Verde Island" },
	{ id: "58", name: "Cayman Islands" },
	{ id: "59", name: "Central African Republic" },
	{ id: "60", name: "Chad" },
	{ id: "61", name: "Chile" },
	{ id: "2", name: "China" },
	{ id: "62", name: "Christmas Island" },
	{ id: "63", name: "Cocos Islands" },
	{ id: "64", name: "Colombia" },
	{ id: "65", name: "Comoros" },
	{ id: "68", name: "Cook Islands" },
	{ id: "69", name: "Costa Rica" },
	{ id: "70", name: "Croatia" },
	{ id: "71", name: "Cuba" },
	{ id: "72", name: "Cyprus" },
	{ id: "73", name: "Czech Republic" },
	{ id: "67", name: "Democratic Republic of Congo" },
	{ id: "74", name: "Denmark" },
	{ id: "75", name: "Djibouti" },
	{ id: "76", name: "Dominica" },
	{ id: "77", name: "Dominican Republic" },
	{ id: "78", name: "Ecuador" },
	{ id: "79", name: "Egypt" },
	{ id: "80", name: "El Salvador" },
	{ id: "81", name: "Equatorial Guinea" },
	{ id: "82", name: "Eritrea" },
	{ id: "83", name: "Estonia" },
	{ id: "84", name: "Ethiopia" },
	{ id: "85", name: "Faeroe Islands" },
	{ id: "86", name: "Falkland Islands" },
	{ id: "87", name: "Fiji Islands" },
	{ id: "88", name: "Finland" },
	{ id: "3", name: "France" },
	{ id: "89", name: "French Guiana" },
	{ id: "90", name: "French Polynesia" },
	{ id: "91", name: "Gabon" },
	{ id: "92", name: "Gambia" },
	{ id: "93", name: "Georgia" },
	{ id: "4", name: "Germany" },
	{ id: "94", name: "Ghana" },
	{ id: "95", name: "Gibraltar" },
	{ id: "96", name: "Greece" },
	{ id: "97", name: "Greenland" },
	{ id: "98", name: "Grenada" },
	{ id: "99", name: "Guadeloupe" },
	{ id: "100", name: "Guam" },
	{ id: "101", name: "Guatemala" },
	{ id: "102", name: "Guinea" },
	{ id: "103", name: "Guinea-Bissau" },
	{ id: "104", name: "Guyana" },
	{ id: "105", name: "Haiti" },
	{ id: "106", name: "Honduras" },
	{ id: "5", name: "Hong Kong" },
	{ id: "6", name: "Hungary" },
	{ id: "107", name: "Iceland" },
	{ id: "108", name: "India" },
	{ id: "109", name: "Indonesia" },
	{ id: "110", name: "Iran" },
	{ id: "111", name: "Iraq" },
	{ id: "112", name: "Ireland" },
	{ id: "7", name: "Israel" },
	{ id: "8", name: "Italy" },
	{ id: "113", name: "Ivory Coast" },
	{ id: "114", name: "Jamaica" },
	{ id: "9", name: "Japan" },
	{ id: "115", name: "Jordan" },
	{ id: "116", name: "Kazakhstan" },
	{ id: "117", name: "Kenya" },
	{ id: "118", name: "Kiribati" },
	{ id: "119", name: "Kuwait" },
	{ id: "121", name: "Kyrgyzstan" },
	{ id: "122", name: "Laos" },
	{ id: "123", name: "Latvia" },
	{ id: "124", name: "Lebanon" },
	{ id: "125", name: "Lesotho" },
	{ id: "126", name: "Liberia" },
	{ id: "127", name: "Libya" },
	{ id: "128", name: "Liechtenstein" },
	{ id: "129", name: "Lithuania" },
	{ id: "130", name: "Luxembourg" },
	{ id: "131", name: "Macau" },
	{ id: "132", name: "Macedonia" },
	{ id: "133", name: "Madagascar" },
	{ id: "134", name: "Malawi" },
	{ id: "135", name: "Malaysia" },
	{ id: "136", name: "Maldives" },
	{ id: "137", name: "Mali" },
	{ id: "138", name: "Malta" },
	{ id: "139", name: "Marshall Islands" },
	{ id: "140", name: "Martinique" },
	{ id: "141", name: "Mauritania" },
	{ id: "142", name: "Mauritius" },
	{ id: "143", name: "Mayotte Island" },
	{ id: "144", name: "Mexico" },
	{ id: "145", name: "Micronesia" },
	{ id: "146", name: "Moldova" },
	{ id: "147", name: "Monaco" },
	{ id: "148", name: "Mongolia" },
	{ id: "149", name: "Montenegro" },
	{ id: "150", name: "Montserrat" },
	{ id: "151", name: "Morocco" },
	{ id: "152", name: "Mozambique" },
	{ id: "153", name: "Namibia" },
	{ id: "154", name: "Nauru" },
	{ id: "155", name: "Nepal" },
	{ id: "11", name: "Netherlands" },
	{ id: "156", name: "Netherlands Antilles" },
	{ id: "157", name: "New Caledonia" },
	{ id: "158", name: "New Zealand" },
	{ id: "159", name: "Nicaragua" },
	{ id: "160", name: "Niger" },
	{ id: "161", name: "Nigeria" },
	{ id: "162", name: "Niue" },
	{ id: "163", name: "Norfolk Island" },
	{ id: "120", name: "North Korea" },
	{ id: "164", name: "Norway" },
	{ id: "165", name: "Oman" },
	{ id: "166", name: "Pakistan" },
	{ id: "167", name: "Palau" },
	{ id: "168", name: "Palestine" },
	{ id: "169", name: "Panama" },
	{ id: "170", name: "Papua New Guinea" },
	{ id: "171", name: "Paraguay" },
	{ id: "172", name: "Peru" },
	{ id: "173", name: "Philippines" },
	{ id: "174", name: "Poland" },
	{ id: "12", name: "Portugal" },
	{ id: "175", name: "Puerto Rico" },
	{ id: "176", name: "Qatar" },
	{ id: "66", name: "Republic of Congo" },
	{ id: "177", name: "Reunion Island" },
	{ id: "178", name: "Romania" },
	{ id: "13", name: "Russia" },
	{ id: "179", name: "Rwanda" },
	{ id: "185", name: "San Marino" },
	{ id: "186", name: "Sao Tome & Principe" },
	{ id: "187", name: "Saudi Arabia" },
	{ id: "188", name: "Senegal" },
	{ id: "189", name: "Serbia" },
	{ id: "190", name: "Seychelles" },
	{ id: "191", name: "Sierra Leone" },
	{ id: "14", name: "Singapore" },
	{ id: "192", name: "Slovakia" },
	{ id: "193", name: "Slovenia" },
	{ id: "194", name: "Solomon Islands" },
	{ id: "195", name: "Somalia" },
	{ id: "196", name: "South Africa" },
	{ id: "10", name: "South Korea" },
	{ id: "15", name: "Spain" },
	{ id: "197", name: "Sri Lanka" },
	{ id: "180", name: "St. Helena" },
	{ id: "181", name: "St. Kitts" },
	{ id: "182", name: "St. Lucia" },
	{ id: "183", name: "St. Pierre & Miquelon" },
	{ id: "184", name: "St. Vincent" },
	{ id: "198", name: "Sudan" },
	{ id: "199", name: "Suriname" },
	{ id: "200", name: "Swaziland" },
	{ id: "201", name: "Sweden" },
	{ id: "202", name: "Switzerland" },
	{ id: "203", name: "Syria" },
	{ id: "204", name: "Taiwan" },
	{ id: "205", name: "Tajikistan" },
	{ id: "206", name: "Tanzania" },
	{ id: "207", name: "Thailand" },
	{ id: "208", name: "Togo" },
	{ id: "209", name: "Tonga" },
	{ id: "210", name: "Trinidad & Tobago" },
	{ id: "211", name: "Tunisia" },
	{ id: "16", name: "Turkey" },
	{ id: "212", name: "Turkmenistan" },
	{ id: "213", name: "Turks & Caicos" },
	{ id: "214", name: "Tuvalu" },
	{ id: "215", name: "Uganda" },
	{ id: "216", name: "Ukraine" },
	{ id: "217", name: "United Arab Emirates" },
	{ id: "18", name: "United Kingdom" },
	{ id: "19", name: "United States" },
	{ id: "218", name: "Uruguay" },
	{ id: "219", name: "Uzbekistan" },
	{ id: "220", name: "Vanuatu" },
	{ id: "221", name: "Vatican City" },
	{ id: "222", name: "Venezuela" },
	{ id: "17", name: "Vietnam" },
	{ id: "223", name: "Wallis & Futuna" },
	{ id: "224", name: "Western Samoa" },
	{ id: "225", name: "Yemen" },
	{ id: "226", name: "Zambia" },
	{ id: "227", name: "Zimbabwe" }
];

export { countries };