import React, { useEffect, useState } from 'react';
import EventCategoryItems from './EventsCategoryItems';
import {
  getEventsCategories,
  getEventsCategoriesBeta,
} from '../../../scripts/dataHandlers';

export default function EventsCategory(props) {
  const [allEventsCategory, setAllEventsCategory] = useState(null);
  console.log(props);
  useEffect(() => {
    const getData = async () => {
      // const res = await getEventsCategories(props.organizationId);
      const res = await getEventsCategories(props.organizationId);
      console.log(`res events based on category id`, res.data.categories);
      setAllEventsCategory(res.data.categories);
    };
    if (props.organizationId) {
      getData();
    }
  }, [props.organizationId]);

  return (
    allEventsCategory &&
    allEventsCategory.map((el) => (
      <>
        <EventCategoryItems
          organizationId={props.organizationId}
          categoryId={el.categoryId}
          heading={el.name}
          limit={props.limit}
          styles={props.styles}
        />
      </>
    ))
  );
}
