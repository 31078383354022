import { useHistory } from 'react-router-dom';
import { getImageByKey } from '../../scripts/getImageByKey';
import { useTranslation } from 'react-i18next';
import * as classes from './Footer.module.css';

const BottomBar = (props) => {
  const history = useHistory();

  const { t } = useTranslation();

  return (

    <div className={
      props.extraClassname
        ? `${classes.Footer_primary} ${props.extraClassname}`
        : `${classes.Footer_primary}`
    }>
      <div className={`${classes.Footer} font-200`}>
        <div className={classes.Footer_SiteLogo}>
          <img
            className='navBarSiteLogo'
            src={props.siteLogo && props.siteLogo !== '' ? props.siteLogo : getImageByKey('siteLogo')}
            title={t('Back to Home')}
            alt='SiteName'
            onClick={() => history.push('/')}
            style={props?.styles?.navBar}
          />
        </div>

        <div
          className={classes.Footer_OrganizationInfo}
          style={props.extraClassname && props.extraClassname === "hhj" ? { display: "none" } : { display: "block" }}
        >
          <div className={classes.Footer_Info_OrganizationName}>
            Tampereen kauppakamari
          </div>
          <div className={classes.Footer_Inforow}> Kalevalantie 2 D, 5.krs</div>
          <div className={classes.Footer_Inforow}> 33100 Tampere</div>
          <div className={classes.Footer_Inforow}> puh. (03) 230 0555</div>
          <div className={classes.Footer_Inforow}>
            {' '}
            info@tampereenkauppakamari.fi
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomBar;

/*
<div className="bottomBar" style={props.styles?.bottomBar}>
            <div className="footerLine" style={props.styles?.footerLine}></div>
            <div className="footer" style={props.styles?.footer}>
                <div className="footerInfo" style={props.styles?.footerInfo}>
                    <div className="footerInfoName" style={props.styles?.footerInfoName}>Nordic Content Distribution Oy</div>
                    <div className="footerInfoAddress" style={props.styles?.footerInfoAddress}>
                        Vanha talvitie 11 A
                        <br />
                        00580 Helsinki
                        <br />
                        Finland
                    </div>
                    <div className="footerInfoPhoneEmail" style={props.styles?.footerInfoPhoneEmail}>
                    tuki@kirjastokino.fi
                        <br />

                    </div>
                </div>
                <div
                    className="footerTos"
                    onClick={() => clickTOS()}
                    style={props.styles?.footerTos}
                > TOS
                </div>
            </div>
        </div>
        */
