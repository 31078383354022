import EventsCategoryItems from './EventsCategoryItems';
import * as classes from './EventsByCategoryId.module.css';

export default function EventsByCategoryId(props) {

  return (
    <>

      <div
        className={`${classes.CategoryItem_main_info_title} ${props.extraClassname ? 'font-700' : 'font-500'
          }`}
      >
        {props.heading}
      </div>
      <EventsCategoryItems
        organizationId={props.organizationId}
        categoryId={props.categoryId}
        limit={props.limit}
        styles={props.styles}
      />
    </>
  );
}
