import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classes from './DetailsShare.module.css';
import HelmetMetaData from '../ShareSocialMedia/HelmetMetaData';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { organization } from '../../configs/config_settings.json';

const DetailsShare = (props) => {
  return (
    <div className={`${classes.details_share}`}>
      <HelmetMetaData
        image={props.chosenItem?.bannerImageSmall || props.chosenItem?.coverImageSmall || props.chosenImage?.thumbnailSmall}
      />

      <FacebookShareButton
        url={window.location.href}
        quote={`${props.chosenItem?.serie?.title || props.chosenItem?.title || props.chosenItem?.name}\n\n
        ${props.chosenItem?.serie?.description || props.chosenItem?.ingress || props.chosenItem?.description}
        `}
        hashtag={`#${organization.name || props.chosenItem?.title || props.chosenItem?.serie?.title || props.chosenItem?.name}`}
        image={props.chosenItem.coverImageSmall}
        className={classes.socialMediaButton}
        >
        <FontAwesomeIcon
          icon={['fab', 'facebook']}
          className={`${classes.shareBTN} font-800`}
        />
      </FacebookShareButton>

      <TwitterShareButton
        url={window.location.href}
        title={`${props.chosenItem?.serie?.title || props.chosenItem?.title || props.chosenItem?.name }\n`}
        image={props.chosenItem.coverImageSmall}
        hashtags={[
          ...(props.chosenItem?.title ? [props.chosenItem.title] : []),
          ...(props.chosenItem?.serie?.title ? [props.chosenItem.serie.title] : []),
          ...(props.chosenItem?.name ? [props.chosenItem.name] : []),
          ...(organization.name ? [organization.name] : []),
        ]}
        //via='Somenamehere, shows up like @something in end of tweet'
        className={classes.socialMediaButton}
        >
        
        <FontAwesomeIcon
          icon={['fab', 'twitter']}
          className={`${classes.shareBTN} font-800`}
        />
      </TwitterShareButton>
    </div>
  );
};

export default DetailsShare;


/*
<Link
        to={{
          pathname: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${props.chosenItem.name}`,
        }}
        target='_blank'
        rel='noreferrer noopener'
      >
        <FontAwesomeIcon
          icon={['fab', 'facebook']}
          className='shareBTN shareFB font-800'
        />
      </Link>

      <Link
        to={{
          pathname: `https://twitter.com/share?text=${props.chosenItem.name} url=${window.location.href}&image=${props.chosenItem.coverImageSmall}`,
        }}
        target='_blank'
        rel='noreferrer noopener'
      >
        <FontAwesomeIcon
          icon={['fab', 'twitter']}
          className='shareBTN shareTW font-800'
        />
      </Link>
*/
