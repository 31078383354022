import React from 'react';
import * as classes from '../Events.module.css';
import * as classesVideo from './EventsItem.module.css';
import VideosImage from '../../VideoComponent/VideosImage';
import EventText from '../EventText';
import { convertDuration } from '../../../scripts/utils';
import moment from 'moment';
import { getImageByKey } from '../../../scripts/getImageByKey';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useMyContext } from '../../../contexts/StateHolder';
import { useTranslation } from 'react-i18next';

export default function EventsItem(props) {
  const { allEvents, organizationId, categoryId } = props;
  const { t } = useTranslation();

  const { setSingleEventItem } = useMyContext();
  const history = useHistory();
  let secondaryClassName = props.secondaryClassName
    ? 'ViewedSectionSecondary__2videos'
    : 'ViewedSectionSecondary__3videos';
  let container = props.secondaryClassName
    ? 'container_2videos'
    : 'container_3videos';

  if (props.homepageContainer) {
    container = `${container} container_homePage`;
  }
  let imageName;
  const clickhandler = (el) => {
    console.log(el);
    setSingleEventItem(el);

    history.push(`/event/${organizationId}/${el.eventId}`);
  };
  //   as a general note, if the event has a 'publicName', 'durationPublic', 'startTimePublic' those are what should be displayed to end users (but events are not required to have these public fields so not all events have them. If the event doesnot have 'publicName', 'durationPublic', 'startTimePublic'  use 'name' 'duration' 'startTime)
  console.log(props);
  return allEvents ? (
    <>
      <div className={classes[container]} style={props.styles}>
        {props.heading && !props.hideViewAll && (
          <div className={classes.Events_main}>
            <div className={classes.Events_main_info}>
              <div
                className={`${classes.CategoryItem_main_info_title} ${
                  props.extraClassname ? 'font-700' : 'font-500'
                }`}
              >
                {props.heading}
              </div>

              {allEvents.length > 0 && (
                <div className={`${classes.CategoryItem_main_link} font-200`}>
                  {props.upcomingEventCategory ? (
                    <>
                      {props.heading === 'Upcoming Events' ? (
                        <Link
                          to={`/events/Upcoming Events/${organizationId}/upcomingEvent`}
                        >
                          {t('eventsCategory.viewAllButton')}
                        </Link>
                      ) : (
                        <Link
                          to={`/events/Live Now Events/${organizationId}/liveEvent`}
                        >
                          {t('eventsCategory.viewAllButton')}
                        </Link>
                      )}
                    </>
                  ) : (
                    <Link
                      to={`/events/${props.heading}/${organizationId}/${categoryId}`}
                    >
                      {t('eventsCategory.viewAllButton')}
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {allEvents.length > 0 ? (
          <div className={classes[secondaryClassName]}>
            {allEvents.map((el, i) => {
              imageName = el.thumbnailImage ? null : 'icareus_event_default';

              return (
                <div
                  className={`${classesVideo.ViewedSectionSecondary__2videos__main} ${classes.Events}`}
                  key={i}
                >
                  <div className={classesVideo.Events_Image}>
                    <VideosImage
                      imageNameWeb={imageName ? null : el.thumbnailImage}
                      imageName={imageName ? imageName : null}
                      showPlayIcon={true}
                      showLikeIcon={false}
                      likes='2.75'
                      duration='4:19'
                      top='50%'
                      left='50%'
                      onClick={() => clickhandler(el)}
                      playIconSize='2x'
                    />
                  </div>
                  {/* // description is based on language so we get the language value first. Description includes html tags so regular expression removes it  */}
                  <EventText
                    title={
                      el.publicName[el.defaultLanguage]
                        ? el.publicName[el.defaultLanguage]
                        : el.name[el.defaultLanguage] &&
                          el.name.en_US.replace(/<[^>]*>?/gm, '')
                    }
                    onClick={() => clickhandler(el)}
                    info={
                      el.startTimePublic
                        ? moment(el.startTimePublic).format(
                            'MMMM Do YYYY, h:mm '
                          )
                        : moment(el.startTime).format('MMMM Do YYYY, h:mm ')
                    }
                    duration={
                      el.durationPublic
                        ? convertDuration(el.durationPublic)
                        : convertDuration(el.duration)
                    }
                  />{' '}
                </div>
              );
            })}
          </div>
        ) : (
          <div className={classes.NoDataAvailable}>
            <h3 className='font-600'>Ei tapahtumia</h3>
            <img
              src={getImageByKey('no_data_available')}
              alt='no_data_available'
            />
          </div>
        )}
      </div>
    </>
  ) : (
    <div className='display-flex-center'>
      {' '}
      <Loader type='TailSpin' color='#0074d9' />{' '}
    </div>
  );
}
