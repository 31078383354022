import './App.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import NavBar from './components/NavBars/NavBar';
import CategoryContentSimilar from './components/ViewAssets/Sliders/CategoryContentSimilar';
import DetailsVideo from './components/Details/DetailsVideo';
//import DetailsSerie from './components/Details/DetailsSerie';
import FAQ from './components/FAQ/FAQ';
import TOS from './components/TOS/TOS';
import ChosenCategoryGrid from './components/ViewAssets/Grids/ChosenCategoryGrid';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useMyContext } from './contexts/StateHolder';
import BottomBar from './components/Footers/BottomBar';
//import Profile from './components/Profile/Profile';
import settings from './configs/config_settings.json';
import Banner from './components/ViewAssets/BannersSlider/Banner';
import Promo from './components/ViewAssets/Promos/Promo';
import Search from './components/Search/Search';
import CategoriesWithTitles from './components/ViewAssets/Sliders/CategoriesWithTitles';
import { getCategories, getRootSubCategories } from './scripts/dataHandlers';
//import LanguageSelect from './components/LanguageSelect/LanguageSelect';
//import RenderCategoryDropdown from './components/DropDowns/CategoryDropdown';
//import LoginBTN from './components/Login/LoginBTN';
import NavBarButton from './components/NavBars/NavBarButton';
import AssetVideoPlayer from './components/VideoPlayer/AssetVideoPlayer';
//import SignUpForm from './components/SignUp/SignUpForm';
//import LoginForm from './components/Login/LoginForm';
import { useCookies } from 'react-cookie';
import './Shared/FontAwesomeIcon';
import 'react-multi-carousel/lib/styles.css';
import ListAllSeries from './components/ViewAssets/Sliders/Series/ListAllSeries';
//import UpcomingEvent from './components/Events/Event/UpcomingEvent';
import UpcomingEvents from './components/Events/EventsCategory/UpcomingEvents';
import LiveNowEvents from './components/Events/EventsCategory/LiveNowEvents';
//import EventsEditorChoice from './components/Events/EventsEditorChoice/EventsEditorChoice';
//import EditorChoice from './components/EditorChoice/EditorChoice';
import EventsCategory from './components/Events/EventsCategory/EventsCategory';
//import BannerSingleAsset from './components/ViewAssets/BannersSingleAsset/BannerSingleAsset';
import * as classes from './components/NavBars/NavBar.module.css';
import UpcomingEvent from './components/Events/Event/UpcomingEvent';
import DetailsSeasonsAndEpisodes from './components/Details/DetailsSeasonsAndEpisodes';
//import DetailsAssetBanner from './components/Details/DetailsAssetBanner';
import EventsCategoryItem from './components/Events/EventsCategory/EventsCategoryItem';
import { useTranslation } from 'react-i18next';

// Google Analytics
import ReactGA from 'react-ga';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// toastify
import 'react-toastify/dist/ReactToastify.css';
import EventsByCategoryId from './components/Events/EventsCategory/EventsByCategoryId';
import { getImageByKey } from './scripts/getImageByKey';

//import EpisodesWithSerieTitles from './components/ViewAssets/Sliders/Series/EpisodesWithSerieTitles/EpisodesWithSerieTitles';

if (settings.googleAnalytics !== '') {
  // Initialize Google analytics with API-key from .env file // TODO
  ReactGA.initialize(settings.googleAnalytics);
}

function App() {
  // Bring stateholders from context
  const {
    setAllCategories,

    key,
    setKey,

    organizationId,
    setOrganizationId,

    language,
    setLanguage,

    setBaseRoutes,

    user,
    setUser,
  } = useMyContext();

  const [cookies] = useCookies('');

  const { t } = useTranslation();
  const location = useLocation();

  // UseEffect that will re-trigger on every pagechange, sending current location to Google Analytics
  useEffect(() => {
    if (settings.organization.googleAnalytics !== '') {
      // Report Google Analytics about user entering this page
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [location]);

  /**** STEP 1, FIRST TIME INIT PROGRAM ****/
  useEffect(() => {
    async function initProgram() {
      try {
        // TODO: Import from suite, now importing just from config.json

        // Set organizationId to context
        setOrganizationId(settings.organization.organizationId);

        // Set key to context
        setKey(settings.organization.key);

        setBaseRoutes(settings.routes);

        console.log('app.js cookies: ', cookies);
        // If there's existing userToken in cookies
        if (cookies?.userData?.userToken) {
          // Get user from context and copy it to modifiedUser
          let modifiedUser = { ...user };

          // Change status of user as logged in
          modifiedUser.loggedIn = true;

          // Set userToken from cookies
          modifiedUser.userId = cookies?.userData?.userId;

          // Set userToken from cookies
          modifiedUser.userToken = cookies?.userData?.userToken;

          // Update user in context, with modified data
          setUser(modifiedUser);
        }

        // Set language from config and set it to context
        setLanguage(settings.language);
      } catch (err) {
        console.log(err);
      }
    }

    initProgram();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**** STEP 2, CHECK LANGUAGE AND GET CATEGORIES (STEP WILL BE REPEATED IF LANGUAGE CHANGES) ****/
  useEffect(() => {
    async function getAndSetCategories() {
      try {
        // Get categories from datahandler
        const categoryList = await getCategories(
          organizationId,
          key,
          language,
          user
        );

        // Set categories in context
        setAllCategories(categoryList);
      } catch (err) {
        console.log(err);
      }
    }

    // Get categories only if language is set
    if (language !== '') {
      getAndSetCategories();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const navBarLinks = () => {
    return (
      <>
        <div className={classes.navBarRow_primary}>
          <NavBarButton
            route={settings.routes.videos}
            name={t('navBar.videos')}
          />

          <NavBarButton
            route={settings.routes.series}
            name={t('navBar.series')}
          />

          <NavBarButton
            route={settings.routes.events}
            name={t('navBar.events')}
          />

          <NavBarButton
            route={settings.routes.hhj}
            name={t('navBar.hhj')}
          />
        </div>

        <div className={classes.navRight}>
          {' '}
          <NavBarButton
            icon='FaSearch'
            route={settings.routes.search}
            name={t('navBar.search')}
          />
        </div>
      </>
    )
  }

  return (
    <div className='App'>
      <div className='container'>
        <Switch>
          {/* ROUTE FOR PLAY VIDEO*/}
          {/*
          <Route path={`/${settings.routes.playVideo}/:orgId/:asset`}>
            <AssetVideoPlayer backRoute={false} playerAutoStart={true} />
          </Route>

           */}

          {/* ROUTE FOR HHJ VIEW*/}
          <Route path={`/${settings.routes.hhj}`} exact>
            <NavBar
              links={navBarLinks()}
              siteLogo={getImageByKey("hhjLogo")}
              extraClassname={"hhj"}
            />
            <Banner
              settings={settings.components.hhjPageBanner_01}
              showArrows={false}
              justImage={true}
            />

            <EventsByCategoryId
              styles={{ width: '80%' }}
              heading={t('eventsCategory.hhjEvents')}
              extraClassname={true}
              categoryId={"137130703"}
              organizationId={settings.organization.organizationId}
              limit={3}
            />

            <ChosenCategoryGrid
              settings={settings.components.hhjCategoryGrid_01}
            />

            <BottomBar
              routes={settings.routes}
              siteLogo={getImageByKey("hhjLogo")}
              extraClassname={"hhj"}
            />

          </Route>

          <Route>
            <NavBar
              links={navBarLinks()}
            />

            {/* ROUTE FOR FRONT PAGE */}
            <Route path='/' exact>
              <Banner
                settings={settings.components.frontPageBanner_01}
                showArrows={false}
                justImage={true}
                showTitle={false}
              />

              <Promo settings={settings.components.frontPagePromo_01} />

              <div
                style={{
                  backgroundColor: '#ecf0f1',
                  padding: '10px 0 50px 0',
                  margin: '30px 0 50px 0',
                }}
              >
                <LiveNowEvents
                  organizationId={settings.organization.organizationId}
                />

                <UpcomingEvents
                  organizationId={settings.organization.organizationId}
                  limit={3}
                  extraClassname
                />
              </div>

              <div style={{ width: '70%', margin: '0 auto 5% auto' }}>
                <CategoriesWithTitles
                  settings={settings.components.frontPageCategories_01}
                  extraClassname={true}
                />
              </div>
            </Route>

            {/* Single Event page which is embedded from suite atm */}
            <Route path='/event/:orgId/:eventId' exact>
              <UpcomingEvent />
            </Route>

            {/* We get Events based on categoryId and show them to user */}

            <Route
              path='/events/:categoryName/:organizationId/:categoryId'
              exact
            >
              <div style={{ background: '#ddd', paddingBottom: '20px' }}>
                <EventsCategoryItem />
              </div>
            </Route>

            {/* ROUTE FOR SERIES VIEW*/}
            <Route path={`/${settings.routes.series}`} exact>
              <ListAllSeries
                settings={settings.components.SeriesListAllSeries_01}
                titleName={t('seriesSlider.title')}
              />
            </Route>

            {/* ROUTE FOR EVENTS VIEW*/}
            <Route path={`/${settings.routes.events}`} exact>
              <div style={{ marginBottom: "5%" }}>
                <UpcomingEvents
                  styles={{ width: '80%' }}
                  organizationId={settings.organization.organizationId}
                  limit={3}
                />
                <LiveNowEvents
                  styles={{ width: '80%' }}
                  organizationId={settings.organization.organizationId}
                />

                <EventsCategory
                  styles={{ width: '80%' }}
                  organizationId={settings.organization.organizationId}
                  limit={3}
                />
              </div>
            </Route>

            {/* ROUTE FOR VIDEO PLAY VIEW*/}
            <Route
              path={`/${settings.routes.svodVideoRoute}/:orgId/:asset`}
              exact
            >
              <div style={{ marginBottom: '5%' }}>
                <AssetVideoPlayer
                  backRoute={'hidden'}
                  playerAutoStart={true}
                  backButton={false}
                />

                <DetailsVideo
                  hideBanner={true}
                  hideInfoData={true}
                  routes={settings.routes}
                />
                <CategoryContentSimilar
                  settings={settings.components.svodSimilar_01}
                />
              </div>
            </Route>

            {/* ROUTE FOR SERIE PLAY VIEW*/}
            <Route
              path={`/${settings.routes.svodSerieRoute}/:orgId/:asset`}
              exact
            >
              <div style={{ marginBottom: '5%' }}>
                <AssetVideoPlayer
                  backRoute={'hidden'}
                  playerAutoStart={true}
                  backButton={false}
                />

                <DetailsVideo
                  routes={settings.routes}
                  hideBanner={true}
                  hideInfoData={true}
                />
                <DetailsSeasonsAndEpisodes
                  playVideoRoute={settings.routes.svodSerieRoute}
                />
              </div>
            </Route>

            {/* ROUTE FOR VIDEOS VIEW*/}
            <Route path={`/${settings.routes.videos}`}>
              <div style={{ width: '70%', margin: '0 auto 5% auto' }}>
                <CategoriesWithTitles
                  settings={settings.components.frontPageCategories_02}
                  extraClassname={true}
                />
              </div>
            </Route>

            {/* ROUTE FOR FAQ VIEW*/}
            <Route path={`/${settings.routes.faq}`}>
              <FAQ />
            </Route>

            {/* ROUTE FOR TOS VIEW*/}
            <Route path={`/${settings.routes.tos}`}>
              <TOS />
            </Route>

            {/* ROUTE FOR CATEGORIES VIEW*/}
            <Route
              path={`/${settings.routes.svodCategoriesRoute}/:asset`}
              exact
            >
              <ChosenCategoryGrid
                settings={settings.components.svodChosenCategoryGrid_01}
              />
            </Route>

            {/* ROUTE FOR SERIE CATEGORIES VIEW*/}
            <Route
              path={`/${settings.routes.svodSeriesCategoriesRoute}/:orgId/:asset`}
              exact
            >
              <div style={{ marginBottom: '5%' }}>
                {/* //hideCta hides play button */}
                <DetailsVideo
                  hideCta={true}
                  isSerie={true}
                  hideInfoData={true}
                />
                <DetailsSeasonsAndEpisodes
                  playVideoRoute={settings.routes.svodSerieRoute}
                />
              </div>
            </Route>

            {/* ROUTE FOR PROFILE VIEW*/}
            {/*
              <Route path={`/${settings.routes.profile}`}>
              <Profile
                fields={settings.components.profile.fields}
              />
            </Route>
            */}

            {/* ROUTE FOR SEARCH VIEW*/}
            <Route path={`/${settings.routes.search}`}>
              <Search settings={settings.components.search_01} />
            </Route>

            {/* ROUTE FOR LOGIN VIEW*/}
            {/*
              <Route path={`/${settings.routes.login}`}>
              <LoginForm
                routes={settings.routes}
                redirectRoute={'/'}
              />
            </Route>
            */}

            {/* ROUTE FOR SIGNUP VIEW*/}
            {/* redirectRoute={settings.routes.packages} */}
            {/*
            <Route path={`/${settings.routes.signUp}`}>
              <SignUpForm
                routes={settings.routes}
                redirectRoute={'/'}
                fields={settings.components.signUp.fields}
              />
            </Route>
             */}

            <BottomBar routes={settings.routes} />
          </Route>


        </Switch>
      </div>
    </div>
  );
}

export default App;
