import React, { useEffect, useState } from 'react';
import { getAllEvents, getAllEventsBeta } from '../../../scripts/dataHandlers';
import EventsItem from '../Events/EventsItem';

export default function EventsCategoryItems(props) {
  const [allEventsBasedOnCategoryId, setAllEventsBasedOnCategoryId] =
    useState(null);
  console.log(props);
  useEffect(() => {
    const getData = async () => {
      console.log(
        props.organizationId,
        props.categoryId,
        'event Category if00'
      );
      // const res = await getAllEvents(props.organizationId, props.categoryId);
      // here we send limit of what number of event to be fetched
      const res = await getAllEvents(
        props.organizationId,
        props.categoryId,
        props.limit
      );
      // show only 3 events
      console.log(`res.data.events`, res, props.categoryId);
      setAllEventsBasedOnCategoryId(res.data.events);
    };
    if (props.organizationId && props.categoryId) {
      getData();
    }
  }, [props.organizationId, props.categoryId, props.limit]);

  return (
    allEventsBasedOnCategoryId && (
      <EventsItem
        allEvents={allEventsBasedOnCategoryId}
        heading={props.heading}
        setAllEventsBasedOnCategoryId={setAllEventsBasedOnCategoryId}
        organizationId={props.organizationId}
        categoryId={props.categoryId}
        styles={props.styles}
      />
    )
  );
}
