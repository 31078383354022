import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useMyContext } from '../../../contexts/StateHolder';

import * as classes from './UpcomingEvent.module.css';
import { useParams } from 'react-router-dom';
import { getSingleEvent } from '../../../scripts/dataHandlers';
// Random component

export default function UpcomingEvent() {
  const [singleEvent, setSingleEvent] = useState(null);
  const [defaultLanguage, setDefaudefaultLanguage] = useState(null);
  const [loading, setLoading] = useState(true);

  const { singleEventItem } = useMyContext();
  const { orgId, eventId } = useParams();

  useEffect(() => {
    if (singleEventItem) {
      setSingleEvent(singleEventItem);
      setDefaudefaultLanguage(singleEventItem.defaultLanguage);
      setLoading(false);
    } else {
      console.log(orgId, eventId);
      const getData = async () => {
        const res = await getSingleEvent(orgId, eventId);
        console.log(res, 'res');
        setSingleEvent(res.event);
        setDefaudefaultLanguage(res.event.defaultLanguage);

        setLoading(false);
      };
      getData();
    }
  }, [singleEventItem, orgId, eventId]);

  console.log(singleEvent);
  const Completionist = () => <span>You are good to go!</span>;

  // This is a conditional Renndering. CompletionList will be called when the count down is over
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        // backgroundImage: `url(${singleEvent.thumbnailImage})`,
        <div className={classes.EventPrimary}>
          <div className={`${classes.EventPrimary_title} font-600`}>
            {singleEvent.publicName[defaultLanguage]}
          </div>
          <div className={`${classes.EventPrimary_countdown} font-800`}>
            <div className={classes.EventPrimary_countdown_description}>
              {' '}
              <span>{zeroPad(days)}</span>
              <p className='font-300'>Days</p>
            </div>
            <div className={classes.EventPrimary_countdown_description}>
              <span>{zeroPad(hours)}</span>
              <p className='font-300'>Hours</p>
            </div>
            <div className={classes.EventPrimary_countdown_description}>
              <span>{zeroPad(minutes)}</span>

              <p className='font-300'>Minutes</p>
            </div>
            <div className={classes.EventPrimary_countdown_description}>
              <span>{zeroPad(seconds)}</span>

              <p className='font-300'>Seconds</p>
            </div>
          </div>
          <div className={`${classes.EventPrimary_date} font-300`}>
            <div>
              <FontAwesomeIcon icon='calendar' />
            </div>
            <div>
              {moment(singleEvent.startTimePublic).format(
                ' dddd, MMMM Do YYYY '
              )}
            </div>
          </div>
          <div className={`${classes.EventPrimary_time} font-300`}>
            <div>
              {' '}
              <FontAwesomeIcon icon='clock' />
            </div>
            <div>
              {moment(singleEvent.startTimePublic).locale('en-gb').format('LT')}
            </div>{' '}
          </div>
          <div
            className={classes.EventPrimary_description}
            dangerouslySetInnerHTML={{
              __html: singleEvent.description[defaultLanguage],
            }}
          ></div>
        </div>
      );
    }
  };
  return (
    <>
      <div
        style={{
          background: '#f3f4f8',
          minHeight: '100vh',
        }}
      >
        {!loading && singleEvent && (
          <Countdown date={singleEvent.startTimePublic} renderer={renderer} />
        )}
      </div>
    </>
  );
}
